<template>
  <div class="seaDetail-container">
    <el-drawer
      class="seaDetail-drawer"
      title="出账详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div class="first">
            <div class="title">
              <div>
                <span class="first-tit">{{ tableRow.code || "--" }}</span>
              </div>
              <el-button
                v-if="tableRow.deleteFlag == 0"
                type="primary"
                class="common-screen-btn"
                @click="
                  () => {
                    deleted();
                  }
                "
                >作废</el-button
              >
              <el-button
                v-if="tableRow.deleteFlag == 1"
                class="common-screen-btn"
                plain
                disabled
                >已作废</el-button
              >
            </div>
            <div class="first-con1">
              <span class="con-margin"
                >出账金额：{{ (tableRow.amount&&Number(tableRow.amount).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >出账类型：{{
                  tableRow.expenditureType == "wages"
                    ? "工资"
                    : tableRow.expenditureType == "reimbursement"
                    ? "报销"
                    : tableRow.expenditureType == "supplierSettlement"
                    ? "供应商结算"
                    : tableRow.expenditureType == "refund"
                    ? "退款"
                    : tableRow.expenditureType == "marketingManagement"
                    ? "市场营销"
                    : tableRow.expenditureType == "fine"
                    ? "罚款"
                    : tableRow.expenditureType == "public relationsExpenses"
                    ? "公关费"
                    : tableRow.expenditureType == "rent"
                    ? "房租水电成本"
                    : tableRow.expenditureType == "introduction"
                    ? "介绍费"
                    : "其他"
                }}</span
              >
              <span class="con-margin"
                >出账方式：{{
                  tableRow.recordType == "bankCard"
                    ? "银行卡"
                    : (tableRow.recordType == "alipay"
                        ? "支付宝"
                        : tableRow.recordType == "wechat"
                        ? "微信"
                        : tableRow.recordType == "cash"
                        ? "现金"
                        : tableRow.recordType == "corporateAccount"
                        ? "对公账户"
                        : tableRow.recordType == "generalAccount"
                        ? "对公账户（总）"
                        : tableRow.recordType == "publicAccount"
                        ? "对公账户（分）"
                        : tableRow.recordType == "taobao"
                        ? "淘宝"
                        : tableRow.recordType == "tiktok"
                        ? "抖音"
                        : tableRow.recordType == "pinduoduo"
                        ? "拼多多"
                        : tableRow.recordType == "other"
                        ? "其他"
                        : "--")
                }}</span>
              <span class="con-margin"
                >出款时间：{{ tableRow.recordTime || "--" }}</span
              >
            </div>
            <div class="first-con2">
              <span class="con-margin"
                >收款人：{{ tableRow.transactionName || "--" }}</span
              >
              <!-- <span class="con-margin"
                >收款方式：{{
                  tableRow.recordType == "bankCard"
                    ? "银行卡"
                    : (tableRow.recordType == "alipay"
                        ? "支付宝"
                        : tableRow.recordType == "wechat"
                        ? "微信"
                        : tableRow.recordType == "cash"
                        ? "现金"
                        : "其他") || "--"
                }}</span
              > -->
              <span class="con-margin"
                >收款账户：{{ tableRow.transactionAccount || "--" }}</span
              >
              <span class="con-margin"
                >联系方式：{{ tableRow.transactionPhone || "--" }}</span
              >
            </div>
            <div class="first-con3">
              <span class="con-margin2"
                >备注：{{ tableRow.remark || "--" }}</span
              >
            </div>
          </div>

          <div class="base">
            <div class="data-title">基本信息</div>
            <div>
              <span class="baseinfo"
                >负责人：{{ tableRow.createName || "--" }}</span
              >
              <span class="baseinfo"
                >负责人所属部门：{{ tableRow.departName || "--" }}</span
              ><br />
              <span class="baseinfo"
                >创建人：{{ tableRow.createName || "--" }}</span
              >
              <span class="baseinfo"
                >创建时间: {{ tableRow.createTime || "--" }}</span
              ><br />
              <span class="baseinfo"
                >最后修改人: {{ tableRow.updateName || "--" }}</span
              >
              <span class="baseinfo"
                >最后修改时间：{{ tableRow.updateTime || "--" }}</span
              >
            </div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                图片及文件（建议上传发票、合同、打款记录等）
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">
                  上传附件
                  <input
                    v-if="tableRow.deleteFlag == 0"
                    @input="
                      (e) => {
                        uploadFile(e);
                      }
                    "
                    class="uploadBtn-input"
                    type="file"
                  />
                </div>
                <!-- <div class="uploadBtn-container2">
                  上传附件
                  <input
                    v-if="tableRow.deleteFlag == 1"
                    disabled
                    @input="
                      (e) => {
                        uploadFile(e);
                      }
                    "
                    class="uploadBtn-input"
                    type="file"
                  />
                </div> -->
              </div>
            </div>
            <div
              v-if="tableRow.files && tableRow.files.length"
              class="filesList-container"
            >
              <div
                v-for="(item, index) in tableRow.files"
                :key="index"
                class="fileItem"
              >
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[
                        item.fileUrl.split('.').length - 1
                      ]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
                <!-- <div class="fileItem-name">{{ item.fileName }}</div> -->
                <div class="fileItem-btn-Container">
                  <!-- <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.fileUrl);
                      }
                    "
                  >
                    查看
                  </div> -->
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.fileUrl);
                      }
                    "
                  >
                    下载
                  </div>
                  <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div
              v-if="!tableRow.files || !tableRow.files.length"
              class="filesList-container"
            >
              <div class="fileItem">
                暂无内容
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <el-tabs
            style="height: 100%;display: flex;flex-direction: column;"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane label="工作记录" name="about">
              <!-- <work-log
                :followRecordList="followRecordList"
              ></work-log> -->
              <common-work-log
                :followRecordList="followRecordList"
              ></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog
    v-dialogDrag
      title="作废说明"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="备注" prop="textarea">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="ruleForm.textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- <div style="display: flex;">
        <span>备注</span>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="resetForm"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              getDeleteFlow();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deleteFlow, payoutdelete, payoutUpload,deleteFinancialFlow } from "../../../service/money";
import { upload } from "../../../service/upload.js";
import { DateTransform, Config } from "../../../utils/index.js";
// import WorkLog from "./workLog";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import CommonPicture from "../../../components/common/commonPicture.vue";
import { workLog } from "../../../service/common.js";
export default {
  components: { CommonWorkLog, CommonPicture },
  props: [
    "drawer",
    "tableRow", //传过来一行数据
    "customerInfo",
    "adminInfo",
    "chanceFollowList",
    "custDict",
    "loading",
    "childid"
  ],
  data() {
    return {
      DateTransform,
      Config,
      filesList: [],
      editParams: {
        filedName: "",
        filedValue: ""
      },
      planVisible: false, // 新建计划弹窗
      input2: "",
      activeName: "about", // tabs
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      planParams: {}, // 新建计划提交内容
      planRules: {
        planName: [
          { required: true, message: "请填写计划名称", trigger: "change" }
        ]
      },
      editRules: {
        // address: [{ required: true, message: "请填写地址", trigger: "change" }],
      },
      editRuleForm: {
        address: "",
        markingStatus: "",
        web: "",
        note: "",
        mail: "",
        qq: "",
        wx: "",
        phone: "",
        industryName: "",
        industry: "",
        source: "",
        level: "",
        seaName: "",
        seaType: "",
        name: ""
      },
      planList: [], // 计划列表
      planTask: [], // 提醒列表
      dialogVisible: false,
      ruleForm: {
        textarea: ""
      },
      rules: {
        textarea: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      followRecordList: [] // 工作日志
    };
  },
  methods: {
    async deleteFile(item) {
      this.$emit("loadFun", true);
      let params = {
        fileId: item.fileId,
        filedUrl: item.fileUrl,
        fileName: item.fileName
      };
      const res = await payoutdelete(params);
      if (res.code != 200) {
       return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.$emit("getpayout", this.tableRow);
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
    // 作废
    deleted() {
      this.dialogVisible = true;
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.dialogVisible = false;
    },
    async getDeleteFlow() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return this.$message.error("请填写完整");
        //发送请求
        let id = this.tableRow.id + "";
        let rea = this.ruleForm.textarea;
        if(this.platform == 'distributeSettle'){ //平台作废
          const res = await deleteFinancialFlow({id: this.tableRow.id,remark: rea})
          if (res.code != 200) {
           return this.$message.error("操作失败！");
          }
        }else{
          const res = await deleteFlow({ flowId: id, reason: rea });
          if (res.code != 200) {
           return this.$message.error("操作失败！");
          }
        }
        this.$message.success("操作成功！");
        this.$emit("getpaylist"); //刷新列表
        this.$emit("updatetitle"); //刷新报表
        this.$emit("payoutDetail"); //关闭弹窗
        this.ruleForm.textarea = "";
        this.dialogVisible = false;
      });
    },
    download(url) {
      window.location.href = url;
    },
    async uploadFile(e) {
      // 上传合同
      this.$emit("loadFun", true);
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.$emit("loadFun", false);
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.$emit("loadFun", false);
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      // if (
      //   format != "bmp" &&
      //   format != "jpg" &&
      //   format != "png" &&
      //   format != "gif" &&
      //   format != "jpeg" &&
      //   format != "xls" &&
      //   format != "xlsx" &&
      //   format != "doc" &&
      //   format != "docx" &&
      //   format != "pdf"
      // ) {
      //   this.$emit("loadFun", false);
      //   return this.$message.error("支持上传bmp、jpg、png、gif、jpeg、xls、xlsx、doc、docx、pdf")
      // }
      let resData = (await upload({ file: file })).data;
      // 上传接口
      const res = await payoutUpload({
        id: this.tableRow.id + "",
        fileUrls: [{ fileName: fileName, fileUrl: resData.filename }]
      });
      if (res.code != 200) {
       return this.$message.success("上传失败");
      }
      this.$message.success("上传成功");
      e.target.value = "";
      this.$emit("getpayout", this.tableRow);
    },
    async workLog(id) {
      // 工作日志
      let resData = (await workLog({ id: id, type: 2 })).data;
      if (resData && resData.length) {
        resData.map((item) => {
          // if(item.type == 'uploadFile'){
          //   if (item.value) {
          //   item.value = JSON.parse(item.value).fileUrls;
          //   } else {
          //     item.value = [];
          //   }
          // }else {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = {};
          }
          // }
        });
      }
      this.followRecordList = resData;
    }
  }
};
</script>
<style lang="less" scoped>
.seaDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .borderLine {
    position: relative;
    width: 100%;
    margin-top: 14px;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: #eaeaeb;
      height: 1px;
      width: 120%;
      left: -10%;
      top: 0;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #999;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-tit-info {
        font-size: 18px;
      }
      .hoverEdit {
        display: inline-block;
        width: 333px;
        font-size: 18px;
        padding: 6px 12px;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 220px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner,
        .el-input-group__append {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-top: 16px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
          }
        }
      }
    }
    .data-content {
      .detail-content {
        margin-top: 16px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.data-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #000000a6;
  border-bottom: 1px dashed #eaeaeb;
  margin-top: 16px;
}
.first {
  padding-bottom: 50px;
  border-bottom: 1px solid #eaeaeb;
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .first-tit {
      font-size: 18px;
    }
  }

  .tit-btn {
    display: inline-block;
    width: 73px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 11px;
  }
  .con-margin {
    display: inline-block;
    // margin-right: 68px;
    width: 268px;
    color: #666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .con-margin2 {
    color: #666;
    font-size: 14px;
  }
  .first-con1,
  .first-con2,
  .first-con3 {
    margin-top: 16px;
    display: flex;
    align-items: baseline;
  }
}
.el-textarea {
  width: 80%;
  margin-left: 28px;
}
.baseinfo {
  display: inline-block;
  width: 422px;
  margin-top: 16px;
  color: #666;
  font-size: 12px;
}
.upload-container {
  margin-top: 24px;
  .filesList-container {
    border: 1px solid #ebeef5;
    margin-top: 12px;
    border-radius: 4px;
    .fileItem {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      border-bottom: 1px solid #ebeef5;
      height: 74px;
      line-height: 74px;
      align-items: center;
      .fileItem-btn-Container {
        display: flex;
        .fileItemBtn {
          margin-right: 16px;
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
  }
  .upload-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #666;
      font-size: 14px;
      background: #fff;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      margin-left: 16px;
      position: relative;
      .uploadBtn-input {
        width: 100px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
<style lang="less">
.el-popover {
  .el-popconfirm__action {
    margin-top: 12px;
    .el-button--mini {
      height: 26px !important;
      line-height: 10px;
      margin-left: 16px;
    }
  }
}
</style>
